import { Component } from '@angular/core';
import { StaticVariable } from "src/app/global.class";
import { Router, NavigationStart, NavigationCancel, NavigationEnd, NavigationError } from "@angular/router";
declare var $: any;
declare var bootbox: any;

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css']
})
export class AppComponent {
    lang: string;
    title = 'soundararan';
    audio = new Audio("assets/Audio/Aud.mp3");
    loading = false;

    constructor(private router: Router) {
        this.router.events.subscribe((event) => {
            switch (true) {
                case event instanceof NavigationStart: {
                    this.loading = true;
                    break;
                }

                case event instanceof NavigationEnd:
                case event instanceof NavigationCancel:
                case event instanceof NavigationError: {
                    this.loading = false;
                    break;
                }
                default: {
                    break;
                }
            }
        });
    }
    ngOnInit() {
        this.lang = sessionStorage.getItem('language');
        if (!this.lang) {
            sessionStorage.setItem('language', "ENGLISH");
        }

        this.audio = new Audio("assets/Audio/Aud.mp3");
        this.audio.autoplay = true;
        let g = this;
        $('#play-pause-button').on("click", function () {
            //          alert('click');
            if ($(this).hasClass('fa-play')) {
                $(this).removeClass('fa-play');
                $(this).addClass('fa-pause');
                g.audio.play();

            }
            else {
                $(this).removeClass('fa-pause');
                $(this).addClass('fa-play');
                g.audio.pause();
            }
        });

        g.audio.onended = function () {

            $("#play-pause-button").removeClass('fa-pause');
            $("#play-pause-button").addClass('fa-play');
        };
        g.audio.onplay = function () {
            $('#play-pause-button').removeClass('fa-play');
            $('#play-pause-button').addClass('fa-pause');
        };

        let btn = $('#button');

        $(window).scroll(function () {
            if ($(window).scrollTop() > 300) {
                btn.addClass('show');
            } else {
                btn.removeClass('show');
            }
        });

        btn.on('click', function (e) {
            e.preventDefault();
            $('html, body').animate({ scrollTop: 0 }, '300');
        });

              $(document).ready(function(){
                  $(function() {
                        $(this).bind("contextmenu", function(event) {
                            event.preventDefault();
                            bootbox.alert('Right click disable in this site!!');
                        });
                    });
                });

              var isCtrlHold = false;
              var isShiftHold = false;

              $(document).keyup(function (e) {
                  if (e.which == 17) //17 is the code of Ctrl button
                      isCtrlHold = false;
                  if (e.which == 16) //16 is the code of Shift button
                      isShiftHold = false;
              });
              $(document).keydown(function (e) {
                  if (e.which == 17)
                      isCtrlHold = true;
                  if (e.which == 16)
                      isShiftHold = true;

                  ShortcutManager(e);
              });

              function ShortcutManager(e){
                  //C:
                  if (e.which == 67) { //67 is the code of C button
                      e.preventDefault(); //prevent browser from the default behavior
                      bootbox.alert("Shortcuts Has Been Blocked");
                  }

                  //Ctrl+I:
                  if (isCtrlHold && e.which == 73) { //73 is the code of I button
                      e.preventDefault(); //prevent browser from the default behavior
                      bootbox.alert("Shortcuts Has Been Blocked");
                  }

                  //Ctrl+U:
                  if (isCtrlHold && e.which == 85) { //85 is the code of U button
                      e.preventDefault(); //prevent browser from the default behavior
                      bootbox.alert("Shortcuts Has Been Blocked");
                  }

                //Ctrl+S:
                  if (isCtrlHold && e.which == 83) { //83 is the code of S button 
                      e.preventDefault(); //prevent browser from the default behavior
                      bootbox.alert("Shortcuts Has Been Blocked");
                  }
              }
    }

    onActivate() {
        window.scroll(0,0); 
      }

}
