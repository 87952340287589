import { Component, OnInit } from '@angular/core';
import { Router } from "@angular/router";
import { StaticVariable } from "src/app/global.class";

declare var $: any;

@Component( {
    selector: 'app-nav',
    templateUrl: './nav.component.html',
    styleUrls: ['./nav.component.css']
} )
export class NavComponent implements OnInit {
    toggleMenu: boolean;
flag: boolean = true;
message:string;
lang: string;

    constructor( private router: Router ) { }

    ngOnInit() {
        
        $( '.navbar-collapse .ss-menu' ).click( function() {
            $( ".navbar-collapse" ).collapse( 'hide' );
        } );
        
        this.lang = sessionStorage.getItem('language');
        if(this.lang=='ENGLISH'){
            this.flag = true;       
            }
        else{
            this.flag = false;  
        }
        
        $(document).ready(function() {
            var $navbar = $("#mNavbar");
            
            AdjustHeader(); // Incase the user loads the page from halfway down (or something);
            $(window).scroll(function() {
                AdjustHeader();
            });
            
            function AdjustHeader(){
              if ($(window).scrollTop() > 400) {
                if (!$navbar.hasClass("navbar-fixed-top")) {
                  $navbar.addClass("navbar-fixed-top");
                }
              } else {
                $navbar.removeClass("navbar-fixed-top");
              }
            }
          });
        
        
        
        /*Active Class*/
        
       

    }
    
  
    ontoggleMenu() {
        if ( this.toggleMenu === true ) {
            this.toggleMenu = false;
        } else {
            this.toggleMenu = true;
        }

    }
    
    languageChange(){
        if(this.lang=="ENGLISH"){
            sessionStorage.setItem( 'language',"TAMIL" );
            location.reload();
        }
        else{
            sessionStorage.setItem( 'language',"ENGLISH" );
            location.reload();
        }
    }

    logout() {

        this.router.navigate( [``] );
    }
}