import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { StaticVariable } from '../global.class';
import { LatestNewsPojo } from '../pojo/latest-news.pojo';

@Injectable({
  providedIn: 'root'
})
export class LatestNewsService {

  constructor(private httpClient: HttpClient) { }

  getaAllPost(): Observable<LatestNewsPojo[]> {
    const url = StaticVariable.porturl + "LatestNewsAll";
    return this.httpClient.get<LatestNewsPojo[]>(url);
  }

}
