import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { NavComponent } from './nav/nav.component';
import { HttpClientModule } from "@angular/common/http";
import { HttpModule } from "@angular/http";
import { FormsModule } from "@angular/forms";


const appRoutes: Routes = [
        { path: 'home', component: HeaderComponent, children: [{ path: '', component: HomeComponent }] },
        { path: '', component: HeaderComponent, children: [{ path: '', component: HomeComponent }], pathMatch: 'full' },
        {
            path: 'basic', component: HeaderComponent,
            loadChildren: './history-module1/history-module1.module#HistoryModule1Module'
        },
        {
            path: 'detail',component: HeaderComponent,
            loadChildren: './history-module2/history-module2.module#HistoryModule2Module'
        }
 
];

@NgModule( {
    declarations: [
        AppComponent,
        HomeComponent,
        HeaderComponent,
        FooterComponent,
        NavComponent,
    ],
    imports: [
        BrowserModule,
        RouterModule.forRoot( appRoutes, { useHash: true } ),
        HttpClientModule,
        HttpModule,
       
    ],
    providers: [],
    bootstrap: [AppComponent]
} )
export class AppModule { }
